<template>
  <v-container fluid>
    <v-row>
      <v-col md="5" class="custom-border-right">
        <table class="width-100">
          <tr>
            <td class="pb-2 font-size-18 text-capitalize">Project #</td>
            <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
              {{ projectArr.barcode }}
            </td>
          </tr>
          <tr>
            <td class="pb-2 font-size-18 text-capitalize">Reference #</td>
            <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
              <template v-if="projectArr.reference">
                {{ projectArr.reference }}
              </template>
              <template v-else>
                <em class="text--secondary">No Reference</em>
              </template>
            </td>
          </tr>
          <tr>
            <td class="pb-2 font-size-18 text-capitalize">Customer</td>
            <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
              <template v-if="projectArr.customer">
                <template
                  v-if="projectArr.customer && projectArr.customer.display_name"
                >
                  {{ projectArr.customer.display_name }}
                </template>
              </template>
              <template v-else
                ><em class="text--secondary">No Customer</em></template
              >
            </td>
          </tr>
          <!--  <tr>
            <td class="pb-2 font-size-18 text-capitalize">
              Customer Purchase Order
            </td>
            <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
              <template v-if="projectArr.customer_purchase_order">{{
                projectArr.customer_purchase_order
              }}</template>
              <template v-else
                ><em class="text--secondary"
                  >No Customer Purchase Order
                </em></template
              >
            </td>
          </tr>
          <tr>
            <td class="pb-2 font-size-18 text-capitalize">Project Value</td>
            <td class="pb-2 font-size-18 font-weight-700 text-capitalize">
              <template v-if="projectArr.project_value">{{
                formatMoney(projectArr.project_value)
              }}</template>
              <template v-else
                ><em class="text--secondary">No Project Value</em></template
              >
            </td>
          </tr>
          <tr>
            <td class="pb-2 font-size-18 text-capitalize">Project Budget</td>
            <td class="pb-2 font-size-18 font-weight-700 text-capitalize">
              <template v-if="projectArr.project_budget">{{
                formatMoney(projectArr.project_budget)
              }}</template>
              <template v-else
                ><em class="text--secondary">No Project Budget </em></template
              >
            </td>
          </tr> -->
          <!--  <tr>
            <td class="pb-2 font-size-18 text-capitalize">Specification</td>
            <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
              <template v-if="projectArr.specification">{{
                projectArr.specification
              }}</template>
              <template v-else
                ><em class="text--secondary">No Specification </em></template
              >
            </td>
          </tr> -->

          <!-- <tr>
            <td class="pb-2 font-size-18 text-capitalize">Status</td>
            <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
              <template v-if="projectArr.status_text">{{
                projectArr.status_text
              }}</template>
              <template v-else
                ><em class="text--secondary">No Status</em></template
              >
            </td>
          </tr> -->
          <!--  <tr>
            <td class="pb-2 font-size-18 text-capitalize">Date Created</td>
            <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
              <template v-if="projectArr.added_at">{{
                formatDateTime(projectArr.added_at)
              }}</template>
              <template v-else
                ><em class="text--secondary">Not Created Yet</em></template
              >
            </td>
          </tr> -->
          <tr>
            <td class="pb-2 font-size-18 text-capitalize font-weight-700">
              Start Date
            </td>
            <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
              <template v-if="projectArr.started_at">{{
                formatDate(projectArr.started_at)
              }}</template>
              <template v-else
                ><em class="text--secondary">Not Start Date</em></template
              >
            </td>
          </tr>
          <!--  <tr>
            <td class="pb-2 font-size-18 text-capitalize">Finished At</td>
            <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
              <template v-if="projectArr.finished_at">{{
                formatDate(projectArr.finished_at)
              }}</template>
              <template v-else
                ><em class="text--secondary">Not Finished Yet</em></template
              >
            </td>
          </tr> -->
          <tr>
            <td class="pb-2 font-size-18 text-capitalize font-weight-700">
              End Date
            </td>
            <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
              <template v-if="projectArr.deadline">{{
                formatDate(projectArr.deadline)
              }}</template>
              <template v-else
                ><em class="text--secondary">No End Date</em></template
              >
            </td>
          </tr>

          <tr v-if="false">
            <td class="pb-2 font-size-18 text-capitalize">
              Total Logged Hours
            </td>
            <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
              <template v-if="projectArr.total_logged_hours">{{
                projectArr.total_logged_hours
              }}</template>
              <template v-else
                ><em class="text--secondary">No Logged Hours</em></template
              >
            </td>
          </tr>
        </table>

        <div class="mt-4 custom-border-top">
          <h3 class="py-2 font-size-18 text-capitalize">description</h3>
          <template v-if="projectArr.description">
            <read-more
              class="custom-read-more font-size-18 pb-1"
              more-str="read more"
              :text="projectArr.description"
              link="#"
              less-str="read less"
              :max-chars="200"
            >
            </read-more>
          </template>
          <template v-else
            ><em class="text--secondary"
              >No description for this project</em
            ></template
          >
        </div>
        <div class="mt-5 custom-border-top" v-if="projectArr.members">
          <v-layout class="py-2">
            <v-flex>
              <h3 class="font-size-18 text-capitalize">members</h3>
            </v-flex>
            <v-flex
              class="text-right mr-4"
              v-if="getPermission('project:update') && projectArr.status < 4"
            >
              <v-btn icon v-on:click="memberDialog = true">
                <v-icon color="cyan">mdi-cog</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-list-item
            v-for="(member, index) in projectArr.members"
            :key="index"
          >
            <v-list-item-avatar>
              <v-img
                :lazy-src="$defaultProfileImage"
                :src="$assetAPIURL(member.user.profile_logo)"
              ></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-size-18">{{
                member.user.full_name
              }}</v-list-item-title>
              <!--  <v-list-item-subtitle
                class="font-size-14 text-capitalize line-height-1-5"
                >total logged hours :
                {{ member.logged_hours }}</v-list-item-subtitle
              > -->
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                icon
                v-if="getPermission('project:delete') && projectArr.status < 4"
                v-on:click="deleteMember(member)"
              >
                <v-icon color="red lighten-1">mdi-delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </div>
      </v-col>
      <v-col md="7">
        <perfect-scrollbar
          ref="sidebarScrollbar"
          :options="{ suppressScrollX: true }"
          class="scroll"
          style="max-height: 89vh position: relative"
        >
          <table class="width-100">
            <template>
              <tr>
                <th colspan="2" class="font-size-18 py-2">Project Costing</th>
              </tr>
              <tr>
                <td class="font-size-18 font-weight-500 py-2" width="200">
                  Project Value
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  <span
                    class="text--uppercase pr-2 font-weight-600"
                    v-if="projectArr && projectArr.project_value"
                    >{{ formatMoney(projectArr.project_value) }}</span
                  >
                  <em v-else class="text--secondary"> no Project Value</em>
                </td>
              </tr>

              <tr>
                <td class="font-size-18 font-weight-500 py-2" width="200">
                  Project Budget
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  <span
                    class="text--uppercase pr-2"
                    v-if="projectArr && projectArr.project_budget"
                    >{{ formatMoney(projectArr.project_budget) }}</span
                  >
                  <em v-else class="text--secondary"> no Project Budget</em>
                </td>
              </tr>
              <tr>
                <td class="font-size-18 font-weight-500 py-2" width="200">
                  Customer Purchase Order
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  <span
                    class="text--uppercase pr-2"
                    v-if="projectArr.customer_purchase_order"
                    >{{ projectArr.customer_purchase_order }}</span
                  >
                  <em v-else class="text--secondary">
                    no Customer Purchase Order</em
                  >
                </td>
              </tr>
            </template>
            <br />
            <template>
              <tr>
                <th colspan="2" class="font-size-18 py-2">
                  Banker Guarantee
                  <v-icon color="cyan" size="20" v-on:click="attchments"
                    >mdi-paperclip</v-icon
                  >
                </th>
              </tr>
              <tr>
                <td class="font-size-18 font-weight-500 py-2" width="200">
                  Bank Name
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  <span
                    class="text--uppercase pr-2 font-weight-600"
                    v-if="projectArr && projectArr.bank_name"
                    >{{ projectArr.bank_name }}</span
                  >
                  <em v-else class="text--secondary"> no Bank Name</em>
                </td>
              </tr>

              <tr>
                <td class="font-size-18 font-weight-500 py-2" width="200">
                  Bank Amount
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  <span
                    class="text--uppercase pr-2"
                    v-if="projectArr && projectArr.bank_amount"
                    >{{ formatMoney(projectArr.bank_amount) }}</span
                  >
                  <em v-else class="text--secondary"> no bank amount</em>
                </td>
              </tr>
              <tr>
                <td class="font-size-18 font-weight-500 py-2" width="200">
                  Remark
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  <span
                    class="text--uppercase pr-2"
                    v-if="projectArr.bank_remark"
                    >{{ projectArr.bank_remark }}</span
                  >
                  <em v-else class="text--secondary"> no Remark</em>
                </td>
              </tr>
            </template>
            <br />
            <template>
              <tr>
                <th colspan="2" class="font-size-18 py-2">
                  Insurance Project
                  <v-icon color="cyan" size="20" v-on:click="attchments"
                    >mdi-paperclip</v-icon
                  >
                </th>
              </tr>
              <tr>
                <template>
                  <td class="font-size-18 font-weight-500 py-2" width="250">
                    Insurance Company
                  </td>
                  <td class="font-weight-600 font-size-18 py-2">
                    <span v-if="projectArr && projectArr.insurance_company">
                      {{ projectArr.insurance_company }}</span
                    >
                    <em v-else class="text--secondary">
                      no Insurance Company</em
                    >
                  </td>
                </template>
              </tr>
              <tr>
                <template>
                  <td class="font-size-18 font-weight-500 py-2" width="250">
                    Insurance Amount
                  </td>
                  <td class="font-weight-600 font-size-18 py-2">
                    <span v-if="projectArr && projectArr.insurance_amount">
                      {{ formatMoney(projectArr.insurance_amount) }}</span
                    >
                    <em v-else class="text--secondary"> no Insurance Amount</em>
                  </td>
                </template>
              </tr>
              <tr>
                <template>
                  <td class="font-size-18 font-weight-500 py-2" width="250">
                    Remark
                  </td>
                  <td class="font-weight-600 font-size-18 py-2">
                    <span v-if="projectArr && projectArr.insurance_remark">
                      {{ projectArr.insurance_remark }}</span
                    >
                    <em v-else class="text--secondary"> no Remark</em>
                  </td>
                </template>
              </tr>
            </template>
          </table>
        </perfect-scrollbar>
        <v-row v-if="false">
          <!--  <v-col md="6" class="custom-border-right" v-if="false">
            <v-layout class="py-2">
              <v-flex>
                <h3 class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  {{ projectArr.open_tasks }} /
                  {{ projectArr.total_tasks }} open tasks
                </h3>
              </v-flex>
              <v-flex class="text-right">
                <v-icon large color="cyan">mdi-check-circle-outline</v-icon>
              </v-flex>
            </v-layout>
            <p class="my-2">{{ projectArr.task_percentage }}%</p>
            <v-progress-linear
              :value="projectArr.task_percentage"
            ></v-progress-linear>
          </v-col>
          <v-col md="6" v-if="false">
            <v-layout class="py-2">
              <v-flex>
                <h3 class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  {{ projectArr.days_left }} / {{ projectArr.total_days }} days
                  left
                </h3>
              </v-flex>
              <v-flex class="text-right">
                <v-icon large color="cyan">mdi-calendar-check</v-icon>
              </v-flex>
            </v-layout>
            <p class="my-2">{{ projectArr.days_percentage }}%</p>
            <v-progress-linear
              :value="projectArr.days_percentage"
            ></v-progress-linear>
          </v-col>
          <v-col md="12" class="custom-border-top" v-if="projectArr.bank_name">
            <div style="border-bottom: 1px solid #ddd">
              <h3 class="font-size-18 text-capitalize font-weight-700">
                Banker Guarantee
              </h3>
            </div>
            <table class="width-100">
              <tr v-if="projectArr.bank_name">
                <td class="pb-2 font-size-18 text-capitalize">Bank Name</td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  {{ projectArr.bank_name }}
                </td>
                <td class="pb-2 font-size-18 text-capitalize">Bank Amount</td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  {{ formatMoney(projectArr.bank_amount) }}
                </td>
              </tr>
              <tr>
                <td class="pb-2 font-size-18 text-capitalize">Remark</td>
                <td
                  colspan="3"
                  class="pb-2 font-size-18 font-weight-600 text-capitalize"
                >
                  {{ projectArr.bank_remark }}
                </td>
              </tr>
            </table>
          </v-col>
          <v-col
            md="12"
            class="custom-border-top"
            v-if="projectArr.insurance_company"
          >
            <div style="border-bottom: 1px solid #ddd">
              <h3 class="font-size-18 text-capitalize font-weight-700">
                Insurance Project
              </h3>
            </div>
            <table class="width-100">
              <tr v-if="projectArr.insurance_company">
                <td class="pb-2 font-size-18 text-capitalize">
                  Insurance Company
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  {{ projectArr.insurance_company }}
                </td>
                <td class="pb-2 font-size-18 text-capitalize">
                  Insurance Amount
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  {{ formatMoney(projectArr.insurance_amount) }}
                </td>
              </tr>
              <tr>
                <td class="pb-2 font-size-18 text-capitalize">Remark</td>
                <td
                  colspan="3"
                  class="pb-2 font-size-18 font-weight-600 text-capitalize"
                >
                  {{ projectArr.insurance_remark }}
                </td>
              </tr>
            </table>
          </v-col>

          <v-col md="12" class="custom-border-top" v-if="false">
            <v-layout>
              <v-flex class="custom-border-right">
                <h3 class="pb-2 font-size-18 text-capitalize">
                  total expenses
                </h3>
                <p class="my-2 font-size-18 font-weight-600 text-capitalize">
                  {{ formatMoney(projectArr.total_expenses) }}
                </p>
              </v-flex>
              <v-flex class="custom-border-right px-2">
                <h3 class="pb-2 font-size-18 text-capitalize">
                  billable expenses
                </h3>
                <p class="my-2 font-size-18 font-weight-600 text-capitalize">
                  {{ formatMoney(projectArr.total_billable_expenses) }}
                </p>
              </v-flex>
              <v-flex class="custom-border-right px-2">
                <h3 class="pb-2 font-size-18 text-capitalize">
                  billed expenses
                </h3>
                <p class="my-2 font-size-18 font-weight-600 text-capitalize">
                  {{ formatMoney(projectArr.total_billed_expenses) }}
                </p>
              </v-flex>
              <v-flex class="px-2">
                <h3 class="pb-2 font-size-18 text-capitalize">
                  unbilled expenses
                </h3>
                <p class="my-2 font-size-18 font-weight-600 text-capitalize">
                  {{ formatMoney(projectArr.total_unbilled_expenses) }}
                </p>
              </v-flex>
            </v-layout>
          </v-col>
          <v-col md="12" class="custom-border-top" v-if="false">
            <div class="card card-custom gutter-b">
              <div class="card-header">
                <div class="card-title justify-space-between width-100">
                  <h3 class="font-size-18 font-weight-600 text-capitalize">
                    Logged Hours
                  </h3>
                  <div
                    class="text-right custom-menu-select py-1 btn btn-primary btn-shadow-hover"
                  >
                    <v-select
                      :items="durationList"
                      v-model="loggedHours"
                      hide-details
                      :disabled="loggedHourLoading"
                      item-color="cyan"
                      class="pt-0 mt-0"
                      v-on:change="getLoggedHours"
                    >
                    </v-select>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <template v-if="loggedHourLoading">
                  <div class="min-height-400px d-flex align-center">
                    <v-progress-linear
                      color="primary"
                      indeterminate
                      rounded
                      height="6"
                    ></v-progress-linear>
                  </div>
                </template>
                <template v-else>
                  <apexchart
                    height="468"
                    type="bar"
                    :options="loggedHourChartoptions"
                    :series="loggedHourSeries"
                  ></apexchart>
                </template>
              </div>
            </div>
          </v-col> -->
        </v-row>
      </v-col>
    </v-row>
    <template v-if="getPermission('project:update')">
      <Dialog :commonDialog="memberDialog">
        <template v-slot:title>Members</template>
        <template v-slot:body>
          <v-container class="py-0">
            <v-row>
              <v-col md="12" class="py-0">
                <v-autocomplete
                  dense
                  color="cyan"
                  filled
                  :items="memberList"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  v-model.trim="members"
                  label="Members"
                  solo
                  flat
                  item-color="cyan"
                  item-text="full_name"
                  item-value="id"
                  hide-details
                  multiple
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 3">
                      <span>{{ item.full_name }}</span>
                    </v-chip>
                    <span v-if="index === 3" class="grey--text font-size-12">
                      <template v-if="members && members.length"
                        >(+{{ members.length - 3 }} others)</template
                      >
                    </span>
                  </template>
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="'No Member Found.'"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:action>
          <v-container class="py-0">
            <v-row>
              <v-col md="12" class="text-right">
                <v-btn
                  v-on:click="memberDialog = false"
                  :disabled="pageLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                >
                  Cancel
                </v-btn>
                <v-btn
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  class="mx-2 custom-bold-button white--text"
                  v-on:click="updateMembers"
                  color="cyan"
                >
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </Dialog>
    </template>
    <template v-if="getPermission('project:delete')">
      <MemberDelete :deleteDialog="deleteMemberDialog">
        <template v-slot:title>Delete Confirmation</template>
        <template v-slot:text>
          <h3 class="font-size-16 font-weight-500 delete-text">
            Once you delete
            <span class="font-weight-700">{{ deleteDetail.full_name }}</span
            >, you won't be able to retrieve this later. Are you sure you want
            to delete
            <span class="font-weight-700">{{ deleteDetail.full_name }}</span>
            ?
          </h3>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="deleteLoading"
            :loading="deleteLoading"
            class="mx-2 custom-bold-button white--text"
            color="red lighten-1"
            v-on:click="deleteMemberConfirm"
          >
            Yes, Delete it
          </v-btn>

          <v-btn
            :disabled="deleteLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="deleteMemberDialog = false"
          >
            No, Keep it
          </v-btn>
        </template>
      </MemberDelete>
    </template>
  </v-container>
</template>

<script>
import { GET, DELETE, PATCH } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import MemberDelete from "@/view/pages/partials/Delete.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";

export default {
  name: "project-detail",
  title: "Project",
  mixins: [CommonMixin, ValidationMixin],
  props: {
    projectArr: {
      type: [Object],
      required: true,
      default: () => {
        return new Object();
      },
    },
    project: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  watch: {
    "projectArr.members"(members) {
      if (members && members.length > 0) {
        this.members = members.map((row) => {
          return row.user.id;
        });
      }
    },
  },
  data() {
    return {
      memberDialog: false,
      pageLoading: false,
      deleteDetail: new Object(),
      deleteMemberDialog: false,
      members: [],
      memberList: [],
      loggedHours: "this_week",
      loggedHourLoading: false,
      loggedHourSeries: [{ data: [] }],
      loggedHourChartoptions: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            borderRadius: 2,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
        },
      },
      durationList: [
        { text: "This Week", value: "this_week" },
        { text: "Last Week", value: "last_week" },
        { text: "This Month", value: "this_month" },
        { text: "Last Month", value: "last_month" },
        { text: "This Year", value: "this_year" },
        { text: "Last Year", value: "last_year" },
      ],
    };
  },
  components: {
    Dialog,
    MemberDelete,
  },
  methods: {
    attchments() {
      let query = { ...this.$route.query };
      query.tab = "attachment";
      this.$router.push({ query });
    },
    deleteMember(member) {
      this.deleteDetail = member;
      this.deleteMemberDialog = true;
    },
    deleteMemberConfirm() {
      const _this = this;
      _this.deleteLoading = true;
      _this.$store
        .dispatch(DELETE, {
          url: "project/" + _this.project + "/members/" + _this.deleteDetail.id,
        })
        .then(() => {
          _this.$emit("reload:project", true);
          _this.deleteDetail = new Object();
          _this.deleteMemberDialog = false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.deleteLoading = false;
        });
    },
    updateMembers() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "project/" + _this.project + "/members",
          data: { members: _this.members },
        })
        .then(() => {
          _this.$emit("reload:project", true);
          _this.memberDialog = false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getMembers() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "project/members",
        })
        .then(({ data }) => {
          _this.memberList = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getLoggedHours() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "project/" + _this.project + "/graph/" + _this.loggedHours,
        })
        .then(({ data }) => {
          _this.loggedHourChartoptions = {
            ..._this.loggedHourChartoptions,
            ...{
              xaxis: {
                categories: data.categories,
              },
            },
          };
          _this.loggedHourSeries = [
            { name: "Logged Hours", data: data.values },
          ];
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
  },
  mounted() {
    this.getMembers();
    this.getLoggedHours();
  },
};
</script>
